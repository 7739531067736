<template>
  <li class="pt-2 pb-4">
    <div class="flex items-center justify-center">
      <a :href="'https://onlycams.solidcams.com/landing/click/cam/'+LiveVerticalPerformer.name+'/click/?AFNO=1-1337-streamstagram'">
        <img class="h-9 w-9 mr-2 rounded-full" v-bind:src="LiveVerticalPerformer.image" alt="">
      </a>
      <div class="flex-2 space-y-1">
        <div class="flex items-center justify-between">
          <h3 class="text-xs font-bold text-gray-900"><a :href="'https://onlycams.solidcams.com/landing/click/cam/'+LiveVerticalPerformer.name+'/click/?AFNO=1-1337-streamstagram'">{{ LiveVerticalPerformer.name }}</a></h3>
        </div>
        <p class="text-xs text-gray-400"><a :href="'https://onlycams.solidcams.com/landing/click/cam/'+LiveVerticalPerformer.name+'/click/?AFNO=1-1337-streamstagram'">Live on Streamstagram</a></p>
      </div>
      <div class="flex-1">
          <p class="text-xs text-blue-400 font-bold text-right"><a :href="'https://onlycams.solidcams.com/landing/click/cam/'+LiveVerticalPerformer.name+'/click/?AFNO=1-1337-streamstagram'">View</a></p>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "LiveVerticalCard",
  props: {
    LiveVerticalPerformer: Object,
  },
};
</script>

<style scoped>

</style>
