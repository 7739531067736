<template>
	<div class="slide">
		<div class="text-center mr-3">
			<div class="rounded-full bg-gradient-to-b from-pink-700 to-yellow-500 instaborder"> 
				<a :href="'https://onlycams.solidcams.com/landing/click/cam/'+LivePerformer.name+'/click/?AFNO=1-1337-streamstagram'" class="bg-white instaborder rounded-full block transform transition hover:rotate-6"> 
					<img v-bind:src="LivePerformer.image" class="rounded-full m-0 p-0 z-0" style="height:76px; width: 76px; min-width: 76px;"> 
				</a> 
			</div> 
			<div>
			<div class="text-xs text-center w-20 overflow-hidden overflow-ellipsis ml-1 mt-1" style=""><a :href="'https://onlycams.solidcams.com/landing/click/cam/'+LivePerformer.name+'/click/?AFNO=1-1337-streamstagram'">{{ LivePerformer.name }}</a></div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "LiveCard",
	props: {
		LivePerformer: Object,
	},
};
</script>

<style>

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 7));
  }
}
.slider {
  background: white;
  height: 106px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;
}
.slider::before, .slider::after {
  content: "";
  height: 106px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
          animation: scroll 40s linear infinite;
  display: flex;
  width: calc(76px * 14);
  will-change: translate;
}
.slider .slide {
  height: 106px;
  width: 100px;
  will-change: translate;
}
</style>
