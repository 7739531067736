<template>
	<div class="font-sans antialiased sticky top-0 z-50">
	  <nav class="flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg border-b border-gray-300 bg-white">
	    <div class="max-w-4xl w-full mx-auto flex flex-wrap items-center justify-between">
	      <div class="w-full relative flex justify-between lg:w-auto px-6 lg:static lg:block lg:justify-start">
	        <router-link :to="{ name: 'PerformerList' }" class="text-medium font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-gray-900">
				<img src="./logo.png" class="w-28 h-auto" alt="Onlycams">
			</router-link>
	        <button class="text-gray-900 cursor-pointer text-xl leading-none px-1 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" v-on:click="toggleNavbar()">
	          <i class="fas fa-bars"></i>
	        </button>
	      </div>
	      <div v-bind:class="{'hidden': !showMenu, 'flex': showMenu}" class="lg:flex lg:flex-grow items-center">
	        <ul class="flex flex-col lg:flex-row list-none ml-auto">
	          <li class="nav-item">
	            <a class="pl-6 lg:px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-gray-900 hover:opacity-75" href="#">
	              <i class="fab fa-facebook-square text-lg leading-lg text-gray-900 opacity-75" /><span class="ml-2">Share</span>
	            </a>
	          </li>
	          <li class="nav-item">
	            <a class="pl-6 lg:px-3 lg:pr-0 py-2 flex items-center text-sm uppercase font-bold leading-snug text-gray-900 hover:opacity-75" href="#">
	              <i class="fab fa-twitter text-lg leading-lg text-gray-900 opacity-75" /><span class="ml-2">Tweet</span>
	            </a>
	          </li>
	        </ul>
	      </div>
	    </div>
	  </nav>
	</div>
  <router-view />
</template>

<style>

</style>

<script>
export default {
  name: "navbar",
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleNavbar: function(){
      this.showMenu = !this.showMenu;
    }
  }
}
</script>
