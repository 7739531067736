<template>
<div id="content" class="h-auto lg:flex lg:flex-col max-w-4xl mx-auto">
  <div class="lg:flex-1 flex static">
    <!-- Scroll wrapper -->
    <div class="bg-gray-100 h-full w-full lg:w-3/4">
      <!-- Scrollable container -->
      <div class="px-6 lg:pl-6 lg:pr-7 py-8 lg:flex-1">
        <!-- Your content -->
          <div class="stories py-3 border border-gray-300 rounded bg-white overflow-x-hidden">
            <div class="w-full flex justify-between pt-1">
              <div class="flex">
                <div class="slider">
                  <div class="slide-track">
                    <LiveCard v-for="LivePerformer in LivePerformers" :key="LivePerformer.id" :LivePerformer="LivePerformer" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="mt-2 pt-4 pb-10 flex flex-col mx-0 w-full">
            <PerformerCard v-for="Performer in Performers" :key="Performer.id" :Performer="Performer" />
          </div>
      </div>
    </div>
    <!-- Fixed sidebar -->
    <div class="bg-gray-100 lg:w-1/4 xl:w-1/4 hidden lg:block">
      <div class="sticky top-14">
        <h1 class="py-4 mt-8 text-gray-500 font-semibold text-sm">
          Suggestions For You
          <div class="float-right text-gray-900">
            <a href="https://onlycams.solidcams.com/landing/click/?AFNO=1-1337-streamstagram">See All</a>
          </div>
        </h1>

        <div>
          <ul> 
            <LiveVerticalCard v-for="LiveVerticalPerformer in LiveVerticalPerformers" :key="LiveVerticalPerformer.id" :LiveVerticalPerformer="LiveVerticalPerformer" />
          </ul>
          <div class="py-4 text-xs">
            <a href="#" class="text-gray-400 text-xs">Copyright Streamstagram 2021</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import PerformerCard from "@/components/PerformerCard.vue";
import LiveCard from "@/components/LiveCard.vue";
import LiveVerticalCard from "@/components/LiveVerticalCard.vue";
import PerformerService from "@/services/PerformerService.js";
import PullToRefresh from "pulltorefreshjs";

export default {
  name: "PerformerList",
  components: {
    PerformerCard,
    LiveCard,
    LiveVerticalCard,
  },
  data() {
    return {
      Performers: null,
      LivePerformers: null,
      LiveVerticalPerformers: null,
    };
  },
  methods : {
    shuffle : function(originalArray) {
      var array = [].concat(originalArray);
      var currentIndex = array.length, temporaryValue, randomIndex;

      while (0 !== currentIndex) {

        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    }
  },
  mounted() {
    PullToRefresh.init({
      mainElement: '#app',
      onRefresh() {
        PerformerService.getPerformers()
          .then((response) => {
            const data = response.data;

            const PerformerData = this.shuffle(data);   
            this.Performers = PerformerData;

            const LivePerformerData = this.shuffle(data);
            this.LivePerformers = LivePerformerData;

            const LiveVerticalPerformerData = this.shuffle(data);
            this.LiveVerticalPerformers = LiveVerticalPerformerData;

            this.$forceUpdate();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  },
  created() {
    PerformerService.getPerformers()
      .then((response) => {
        const data = response.data;

        const PerformerData = this.shuffle(data);   
        this.Performers = PerformerData;

        const LivePerformerData = this.shuffle(data);
        this.LivePerformers = LivePerformerData;

        const LiveVerticalPerformerData = this.shuffle(data);
        this.LiveVerticalPerformers = LiveVerticalPerformerData;
      })
      .catch((error) => {
        console.log(error);
      });
  },

};
</script>

<style scoped>

</style>
