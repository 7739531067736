<template>
	
	<div class="Performer-card rounded overflow-hidden border border-gray-300 w-full bg-white md:mx-0 lg:mx-0 mb-8 lg:mb-16">
		<div class="w-full flex justify-between p-4">
			<div class="flex">
				<div class="rounded-full h-8 w-8 bg-gray-500 flex items-center justify-center overflow-hidden">
				  <a :href="'https://onlycams.solidcams.com/landing/click/cam/'+Performer.name+'/click/?AFNO=1-1337-streamstagram'">
				  	<img class="cursor-pointer" v-bind:src="Performer.thumb" alt="profilepic">
				  </a>
				</div>
				<span class="pt-2 ml-2 font-bold text-sm"><a :href="'https://onlycams.solidcams.com/landing/click/cam/'+Performer.name+'/click/?AFNO=1-1337-streamstagram'">{{ Performer.name }}</a></span>
			</div>
			<span class="px-2 hover:bg-gray-300 cursor-pointer rounded"><a :href="'https://onlycams.solidcams.com/landing/click/cam/'+Performer.name+'/click/?AFNO=1-1337-streamstagram'"><i class="fas fa-ellipsis-h pt-1 text-lg"></i></a></span>
		</div>
		<a :href="'https://onlycams.solidcams.com/landing/click/cam/'+Performer.name+'/click/?AFNO=1-1337-streamstagram'">
			<img class="w-full bg-cover" v-bind:src="Performer.image">
		</a>
		<div class="px-3 pb-2">
			<div class="pt-2">
				<a :href="'https://onlycams.solidcams.com/landing/click/cam/'+Performer.name+'/click/?AFNO=1-1337-streamstagram'"><i class="text-2xl far fa-heart cursor-pointer mr-2"></i></a>
				<a :href="'https://onlycams.solidcams.com/landing/click/cam/'+Performer.name+'/click/?AFNO=1-1337-streamstagram'"><i class="text-2xl far fa-comment cursor-pointer mr-2"></i></a>
				<a :href="'https://onlycams.solidcams.com/landing/click/cam/'+Performer.name+'/click/?AFNO=1-1337-streamstagram'"><i class="text-2xl far fa-paper-plane cursor-pointer mr-2"></i></a>
			</div>
			<div class="pt-0">
				<span class="text-sm text-gray-900 font-medium cursor-pointer"><a :href="'https://onlycams.solidcams.com/landing/click/cam/'+Performer.name+'/click/?AFNO=1-1337-streamstagram'">{{randomLikes()}} likes</a></span>
			</div>
			<div class="pt-2">
				<div class="mb-2 text-sm">
				  <span class="font-medium text-gray-900 mr-1">{{ Performer.name }}</span>{{ Performer.about }}
				</div>
			</div>
			<div class="text-sm mb-2 text-gray-400 cursor-pointer font-medium"><a :href="'https://onlycams.solidcams.com/landing/click/cam/'+Performer.name+'/click/?AFNO=1-1337-streamstagram'">View all {{randomComments()}} comments</a></div>
			<div class="mb-2">
				<div class="mb-2 text-sm">
				  <span class="font-medium text-gray-900 mr-1"><a :href="'https://onlycams.solidcams.com/landing/click/cam/'+Performer.name+'/click/?AFNO=1-1337-streamstagram'">{{ Performer.name }}</a></span>{{ Performer.description }}
				</div>
			</div>
		</div>
	 </div>

</template>

<script>
export default {
  name: "PerformerCard",
  props: {
    Performer: Object,
  },
  methods : {
    randomLikes : function(){
      return Math.floor(Math.random() * 250) + 2;
    },
    randomComments : function(){
      return Math.floor(Math.random() * 30) + 2;
    },
  }
};
</script>

<style scoped>
.Performer-link {
  color: #2c3e50;
  text-decoration: none;
}
</style>
